@font-face {
    font-family: 'Roboto-Medium';
    src: url('../../vendors/font/Roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Abdel';
    src: url('../../vendors/font/Abel/Abel-Regular.ttf') format('truetype');
}

body{
    font-family: 'Abdel';
}

li {
    list-style: none;
}

a:active, a:visited {
    text-decoration: none;
}

.body{
    width: 80%;
}

.navbar__logo{
    margin-bottom: 30px;
    width: 140px;
    height: 140px;
}

.navbar__logo img{
    width: 100%;
    height: 100%;
}

.empty-navbar{
    width: 20%;
}


.navbar__menu{
    pointer-events: all;
    margin-left: 25px;
    padding: 0;
}

.navbar__menu li{
    font-size : 26px;
}

.container_icon{
    display: block;
    width: 30px;
    height: 30px;

    img {
        width: 100%;
    }
}

.account__menu{
    position: absolute;
}

.account__menu--closed{
    display: none;
}

.navbar__productcategory{
    margin-top: 4rem;
    margin-bottom: 25px;
    display: flex;
}

.navbar__productcategory li{
    margin: 0 2em;
}

.category__selected{
    text-decoration: underline;
    text-underline-offset: 4px;
}

.container__name{
    margin-left: 25px;
    font-family: 'Abdel';
    margin-bottom: 25px;
    font-size: larger;
}

.container__product{
    width: 30%;
    margin-left: 25px;
}

.container__product__detail{
    width: 100%;
    display: flex;
}

.secondary_images{
    display: flex;
}

.product__info{
    text-align: center;
    margin : 20px 0px;
}

.product__detail__info{
    margin-left: 30px;
}

.product__detail__name{
    margin-bottom: 40px;
}

.product__detail__price{
    margin-bottom: 40px;
}

.container__productimage{
    width: 100%;
    height: 400px;
}

.message_form{
    margin-top: 50px;
}

.container_video{
    width: 80%;
    height: 500px;
}

.container_video video{
    width: 100%;
    height: 100%;
}

.about__content{
    margin-top: 50px;
}

.block__about__content{
    margin-bottom: 5px;
}

.social_section{
    display: flex;
    margin-top: 20px;
    margin-left: 25px;
}

.social_link{
    width: 20px;
    height: 20px;   
}

.social_link + .social_link{
    margin-left: 15px;
}

.social_link img {
    width: 100%;
    height: 100%;
}

.product__list{
    display: flex;
    flex-wrap: wrap;
}

.product__image{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.product__medias{
    width: 500px;
}

.main__image img{
    width: 500px;
    height: 500px;
    object-fit: cover;
}

.main__image video{
    width: 500px;
    height: 500px;
}

.secondary_images img{
    width: 166.66px;
    height: 166.66px;
    object-fit: cover;
}

.darken__image{
    filter: brightness(50%);
}

.extra__media {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.extra__media::after {
    content: "+" attr(data-extra-media);
    position: absolute;
    color:white;
    font-size: 20px;
}

.hidden_media{
    display: none;
}

.section{
    margin: 20px auto;
    opacity: 0;
    animation: apparition 1s 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
}

.column{
    display: flex;
}

.column__text{
    width: 30%;
}

.column__empty{
    width: 10%;
}

.column__image{
    width: 60%;
}

.nav__el {
    text-transform: uppercase;
    color: #7F7F7F;
    font-size: 16px;
    text-decoration: inherit;
    position: relative;
}

.nav__selected{
    color: #000;
}

.nav__el:hover {
    color: #000;
}
  
.nav__el:hover::after {
    width: 25px;
    left: 0;
    background-color: #000;
}

.nav__el::after {
    content: "";
    height: 1px;
    width: 0%;
    background-color: #7F7F7F;
    display: inline-block;
    position: absolute;
    left: 0%;
    bottom: -3px;
    transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.section__gallery{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    opacity: 0;
    animation: apparition 1s 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
}

.gallery_section_item{
    margin-left: 10px;
    color: #7F7F7F;
}

.gallery_section_item_selected{
    color: #000;
}

// Contact Form Style

.contactform{
    width: 60%;

    form{
        margin-top: 25px;
    }
}

.contactform__info{
    margin-top: 5%;
}

.form__entry {
    .column {
        .entry_contacform + .entry_contacform {
            margin-left: 25px;
        }

        .entry_contacform:nth-of-type(1){
            width: 50%;
        }
        .entry_contacform:nth-of-type(2){
            width: 50%;
        }
    }

    .entry_contacform input{
        border: 1px solid #000;
        border-radius: 6px;
        width: 100%;
    }

    textarea{
        border: 1px solid #000;
        border-radius: 6px;
        width: 100%;
        height: 200px;
    }
}

div .item,
a .item{
    display:flex;
    justify-content: center;
    align-items: center;
    
    text-align: center;
    border-radius: 20px;
    position: absolute;
    --r: calc(var(--position) - var(--offset));
    transition: all 0.25s linear;
    transform: translateX(calc(-1 * var(--deltaImage) * var(--r)));
}

.item img{
    height: 100%;
    object-fit: cover;
}

#carousel {
    display: flex;
    overflow-x: hidden;
    overflow-y: hidden;
    align-items: center;
    justify-content: center;
    transform-style: preserve-3d;
}

// Contact Message
.contact_message{
    width: 70%;
}

.contact_message_item:hover{
    cursor: pointer;
}

.back_contact_messages{
    margin-bottom: 20px;
}

// Contact Message Detail 
.contact_message_detail{
    width: 70%;
}

@keyframes apparition{
	from{
		transform: translateY(-5%);
		opacity: 0;
	}
	
	to{
		transform: translateY(0);
		opacity: 1;
	}
}

@media screen and (max-width: 600px) {
    .menu-side {
        display: none;
    }
  }

  @media screen and (min-width: 600px) {
    .icon-menu-top{
        display: none;
    }
  }

// NEW CLASS CSS WITH TAILWIND
.main_content{
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
}