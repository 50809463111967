:root {
    --primary-color: #000;
    --swiper-navigation-size: 30px;
    --swiper-theme-color: #000;
  }

// Exhibition 

html {
    scroll-behavior: smooth;
}

header {
    font-family: minion-pro,Times New Roman,STSong,serif
}

.hidden{
    display: none;
}

.swiper {
    width: 100%;
    height: 500px;

    @media screen and (max-width: 1080px) {
        height: 300px;
    }

    @media screen and (max-width: 768px) {
        height: 200px;
    }
}

.swiper img{
    height: 500px;

    @media screen and (max-width: 1080px) {
        height: 300px;
    }

    @media screen and (max-width: 768px) {
        height: 200px;
    }
    object-fit: cover;
    margin: auto;
}

main.everybody {
    margin: 0;
    font-size: 14px !important;
    font-family: minion-pro,Times New Roman,Times,serif !important;
    background-color: var(--wz-background-color) !important;
    overflow: hidden;
    overflow-y: auto;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    box-sizing: border-box;

    .main-container{
        width: 1080px;
        margin: 0 auto;
        margin-top: 20px;
        background-color: #FFF;
        border-radius: 10px;
        flex: 0 0 100%;
        max-width: 100%;
        position: relative;

        @media (max-width: 1080px) {
            width: 100%;
            margin-top: 0;
        }
    }

    .main-content{
        padding-top: 49px;
        background-color: #FFF;
        flex: 0 0 100%;
        max-width: 100%;
        position: relative;
        width: 85.7%;
        margin: 0 auto;

        @media (max-width: 1080px) {
            width: 91.6%;
        }
    }

    .image-container{
        display: flex;
        justify-content: center;
        position: relative;
        overflow: hidden;
        width: 100%;
        margin: 0 auto;
        aspect-ratio: 16/9;
        border-radius: 10px;
        /* The image used */
        background-image: var(--banner-url);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .data-event{
        display: flex;
        align-items: center;
        background-color: #FFF;
        width: 100%;
        box-sizing: border-box;
        margin: 20px auto;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
        -webkit-box-shadow:0px 2px 4px rgba(0, 0, 0, 0.15);
        -moz-box-shadow:0px 2px 4px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        padding: 23px 1.25rem;
        z-index: 20;

        &.sticky {
            position: sticky;
            top: -1px;
        }
    }

    .data-event-content{
        width: 100%;
        flex: 3;
    }

    .data-event-title{
        margin-top: 0;
        font-size: 1.5em;
        font-weight: bold;
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }

    .data-event-container{
        display: flex;
        flex-direction: row;
        gap: 8px;
        margin-top: 8px;

        .data-event-location {
            display: flex;
            flex-direction: column;
            line-height: 1.4;
        }

        .data-event-date {
            color: var(--primary-color);
            font-weight: 500;
        }

        .data-event-date-icon-path{
            fill: var(--primary-color)
        }
    }

    .everybody-row{
        width: 90%;
        display: flex;
        padding-bottom: 20px;
        margin: 0 auto;
        
        .everybody-column{
            flex: 1;
            padding: 20px 0;
    
            .everybody-card-body{
                padding: 15px;
                margin-bottom: 1rem;
            }

            .everybody-card-widget{
                margin-bottom: 40px;
            }

            .card-text{
                text-align: left;
                display: inline-flex;
                margin-top: 0;

                .card-content{
                    display: flex;
                    flex-direction: column;
                    padding-left: 5px;
    
                    > span {
                        line-height: 1.3em;
                    }
                }
            }
        }


    }



    .sticky{

    }
}

@media (max-width: 1080px) {
    .data-event-cta {
        width: 100%;
        margin-top: 15px;
    }

    main.everybody .data-event{
        padding: 20px 1.25rem;
        flex-direction: column;
    }
}
