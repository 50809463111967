.password__container{
    min-height: 100vh;
    background-color: $color-bg;
}

.password__box{
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 20px -3px rgba(0,0,0,0.1);
    width: 90%;
    max-width: 500px;
    padding: make_em(40,15);

    .btn+.password__forgot{
        margin-top: make_em(10,15);
    }

    .btn{
        margin-top: make_em(30,15);
    }
}

.login_container{
    margin-top: 100px;
    width: 100%;
    text-align: center;
}

.register_container{
    margin-top: 100px;
    width: 100%;
    text-align: center;
    .title{
        font-size: make_em(28,15);
    }
    
    .title+p{
        margin-top: make_em(15,15);
    }

    p{
        color: $color-grey-text;
    }

    p + form{
        margin-top: make_em(30,15);
    }
}

.form__password {
    .input{
        border: 1px solid $color-grey-border;
        padding: make_em(15,15);
        border-radius: 5px;
        width: 50%;
        color: black;
    
        &::placeholder{
            color: $color-grey-text;
        }
    }

    .btn {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }
}

.form__password+.form__password{
    margin-top: make_em(20,15);
}

.form__label{
    display: block;
    color: black;
    font-size: make_em(16,15);
    margin-bottom: make_em(5,16);
}

.input__password{
    border: 1px solid $color-grey-border;
    padding: make_em(15,15);
    border-radius: 5px;
    width: 50%;
    color: black;

    &::placeholder{
        color: $color-grey-text;
    }
}

.password__title{
    .title{
        font-size: make_em(28,15);
    }

    .title+p{
        margin-top: make_em(15,15);
    }

    p{
        color: $color-grey-text;
    }

    p+p{
        margin-top: make_em(5,15);
    }

    svg{
        width: 50px;
        height: auto;
        margin-bottom: make_em(20,15);
    }

}

.password__content{
    margin-top: 20px;
}

.password__title+.password__content{
    margin-top: make_em(30,15);
}

.password__forgot{
    font-size: make_em(14,15);
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    color: $color-grey-text;
    text-decoration: underline;
}

@media(max-width:450px){
    .password__container{
        background-color: #fff;
    }

    .password__box{
        background-color: transparent;
        box-shadow: none;
        padding: 0;
    }
}
