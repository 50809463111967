//$color-text:#exemple;

$color-grey-text:#919191;
$color-grey-text-light:#BEBEBE;
$color-grey-title:#9E9E9E;
$color-primary:#FF7000;
$color-secondary:#60ADE9;
$color-bg:#FBFBFB;
$color-bg-dark:#17334A;
$color-bg-darkest:#142B3E;
$color-placeholder:#9C9C9C;
$color-grey-border:#DFDFDF;
$color-danger:#FA4040;
$color-success:#38cd1a;
$color-warning:orange;
